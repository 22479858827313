<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.xsOnly, 'white': $vuetify.breakpoint.smAndUp}">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp">
      <v-flex xs12 class="max-width-1400 full-width ma-auto px-2">
        <v-card class="light-background" flat tile>
          <v-card-text>
            <h3 class="text-xs-left graphik-bold mb-3 primary--text font-18">AVISO DE PRIVACIDAD PARA USUARIOS DE LA PLATAFORMA DE GORILA SEGUROS.</h3>
            
            <p class="dark-grey--text">
              En cumplimiento de lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la “Ley”), el Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en delante el “Reglamento”) y los Lineamientos del Aviso de Privacidad publicado en el Diario Oficial de la Federación el día 17 de enero de 2013 (en lo sucesivo los “Lineamientos”) y demás disposiciones aplicables, la Sociedad <span class="graphik-bold">ITM REGIOMONTANA AGENTE DE SEGUROS S.A. DE C.V.</span> (en adelante “Gorila”) desea hacer del conocimiento el Aviso de Privacidad para los Usuarios Visitantes y Clientes (consiguientemente referidos como “Usuario”) de su plataforma digital (en adelante “Plataforma”); respecto del tratamiento y protección de los datos de carácter personal de aquellas personas físicas o morales que voluntariamente se comunican, de forma enunciativa más no limitativa, a través de correo electrónico, telefónicamente o cualquier otro medio electrónico, oral o escrito con Gorila, así como llenando formularios en que se recaben datos personales o que ingresen  a la Plataforma, si es que esto implica la obtención de sus datos personales.
            </p>

            <p class="dark-grey--text">
              Para todos los efectos relacionados con el presente Aviso de Privacidad, Gorila, como responsable del tratamiento de los datos personales, señala como domicilio el ubicado en Torre Pabellón – M Suite 3601/02. Avenida Juárez 1102, Colonia Centro Monterrey. C.P. 64000., el cual podrá ser utilizado para oír y recibir todo tipo de notificaciones relacionadas con el tratamiento de los datos personales; expidiendo y poniendo a disposición del Usuario, el presente Aviso de Privacidad, en los siguientes términos:
            </p>

            <h3 class="text-xs-left graphik-bold mt-4 mb-1 font-18 dark-grey--text">I. Finalidad del tratamiento de sus datos personales.-</h3>

            <p class="dark-grey--text">
              La información personal que sea brindada de manera voluntaria por el Usuario, será  principalmente utilizada para llevar un control sobre la base de datos de Gorila, actividades de administración interna, para formar expedientes del Usuario, la realización de actividades comerciales, comisiones mercantiles, la intermediación de la contratación de seguros y sus pólizas mediante el intercambio de propuestas, intermediación y aceptación de las mismas, comercialización y asesoramiento para celebrarlos, para conservarlos, modificarlos o terminarlos, según la mejor conveniencia de los Usuarios, de conformidad con los lineamientos establecidos para tal caso en la Ley de Instituciones de Seguros y Fianzas (en adelante, “LISF”)  y,  el Reglamento de Agentes de Seguros y Fianzas (en adelante, “RASF”) y la identificación para la prestación de los servicios de intermediación que son solicitados o que se proporcionan para campañas de promoción de Gorila. Gorila podrá transferir la información recabada para los fines anteriores a todo proveedor y/o aseguradora con el fin de poder hacer entrega al Usuario de propuestas, intermediación y pólizas de manera íntegra y eficiente.
            </p>

            <p class="dark-grey--text">
              De manera adicional, utilizaremos su información personal proporcionada para las siguientes finalidades, las cuales no son necesarias para el servicio principal solicitado, sin embargo éstas nos permiten y facilitan brindarle una mejor atención y un mejor servicio de calidad, las cuales son: i) fines mercadotécnicos; ii) fines publicitarios; iii) prospección comercial, vi) conocer la experiencia del Usuario a través de encuestas, evaluaciones o similares; y /o, v) hacer llegar a los Usuarios, promociones u ofertas exclusivas. Si usted no está de acuerdo con las finalidades secundarias, podrá manifestar su negativa siguiendo el mecanismo previsto en el numeral “VIII” del presente Aviso. Es preciso señalar que en caso de actualizarse el supuesto antes señalado, Gorila no le podrá brindar los servicios que presta de forma correcta y ésta no será responsable por tal situación.
            </p>
            
            <p class="dark-grey--text">
              Si usted no se encuentra de acuerdo o conforme con los fines secundarios para los cuales será usada su información, contará con un plazo de 5 (cinco) días para manifestar su inconformidad, esto mediante el proceso establecido por Gorila, que en las cláusulas subsecuentes se describe.
            </p>

            <h3 class="text-xs-left graphik-bold mt-4 mb-1 font-18 dark-grey--text">II. Datos personales solicitados.-</h3>

            <p class="dark-grey--text">
              Para efectos del presente Aviso de Privacidad se entenderá por datos de carácter personal, cualquier información que se recabe concerniente a personas físicas o morales identificadas o identificables; y por Usuario, a cualquier persona física o moral identificada o identificable que comunique sus datos de carácter personal a través del sitio web o cualquier otro método de recolección de información aquí mencionados que de manera enunciativa más no limitativa pudiera ser a través de: i) correo electrónico; ii) llenado de formularios por los que se recaban datos personales en la Plataforma; iii) de manera personal; y iv) en general el uso de cualquier servicio presente de la Plataforma que impliquen la comunicación de sus datos personales.
            </p>

            <p class="dark-grey--text">
              Los datos personales que recabará Gorila del Usuario son:     
            </p>
            
            <p class="dark-grey--text">
              <span class="graphik-bold">CLIENTES:</span> i) Nombre, ii) Domicilio, iii) Edad, iv) RFC, v) CURP, vi) Correo electrónico,  vii) Número de cuenta y CLABE para toda transferencia electrónica aplicable, viii) Teléfono de contacto, ix) Dirección fiscal.
            </p>

            <p class="dark-grey--text">
              <span class="graphik-bold">VISITANTES DE LA PLATAFORMA:</span> i) Nombre, ii) Domicilio, iii) Edad, vi) Correo electrónico, ix) Teléfono de contacto.
            </p>

            <h3 class="text-xs-left graphik-bold mt-4 mb-1 font-18 dark-grey--text">III. Redes sociales.-</h3>

            <p class="dark-grey--text">
              Gorila hace del conocimiento de los Usuarios que pudiera estar presente en las redes sociales, como Facebook, Twitter, Instagram y Youtube. El tratamiento de los datos del Usuario que se hagan seguidores de las páginas oficiales de Gorila se regirá por las condiciones previstas en los términos y condiciones de la red social que corresponda en virtud de que cualquier dato que la red social requiera ser recabado es responsabilidad de ésta y no de Gorila por lo que se recomienda el acceso a la política de privacidad y condiciones de uso de las mencionadas redes sociales o en su caso, con el fin de conocer la información derivada del tratamiento de los datos de carácter personal y especialmente las condiciones y finalidades a las que serán destinados los datos que forman parte del perfil del Usuario.
            </p>

            <h3 class="text-xs-left graphik-bold mt-4 mb-1 font-18 dark-grey--text">IV. Protección de datos personales.-</h3>

            <p class="dark-grey--text">
              Los datos personales del Usuario, serán protegidos en todo momento apegándose los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad, principios que se encuentran establecidos en la Ley antes mencionada en el presente escrito, con el fin de darle seguridad y tranquilidad a los Usuarios de que sus datos personales estarán bien resguardados por quien lo tenga y en caso de uso inadecuado de éstos buscar una sanción.
            </p>

            <h3 class="text-xs-left graphik-bold mt-4 mb-1 font-18 dark-grey--text">V. Transferencia de datos personales.-</h3>

            <p class="dark-grey--text">
              Gorila no divulgará o transferirá la información personal recabada del Usuario con nadie más, excepto con sus empresas filiales, a las cuales les podrá transferir los datos personales recabados de los Usuarios, lo anterior con el fin de realizar las actividades principales y secundarias descritas en el Apartado I de este Aviso de Privacidad. 
            </p>

            <h3 class="text-xs-left graphik-bold mt-4 mb-1 font-18 dark-grey--text">VI. Cambios y modificaciones en el presente aviso de privacidad.-</h3>

            <p class="dark-grey--text">
              Gorila conserva su derecho a modificar y/o cambiar el presente Aviso de Privacidad, por lo que hace del conocimiento de los Usuarios que cualquier cambio y/o modificación al contenido que aquí se describe, se les hará llegar a los diferentes Usuarios en el tiempo debido a través de la Plataforma de Gorila, o bien cuando los Usuarios se comuniquen directamente con la empresa, en la llamada se les hará mención de que el Aviso de Privacidad ha sido modificado y que es menester de ellos verificarlo en la Plataforma de Gorila.
            </p>

            <h3 class="text-xs-left graphik-bold mt-4 mb-1 font-18 dark-grey--text">VII. Uso de Cookies.-</h3>

            <p class="dark-grey--text">
              Gorila hace del conocimiento de los Usuarios de la Plataforma, que en ésta se podrán utilizar cookies y enlaces externos con el fin de brindarle un mejor servicio y experiencia de Usuario al navegar en nuestra página, así como ofrecerle nuevos productos y servicios basados en sus preferencias. 
            </p>

            <p class="dark-grey--text">
              Gorila también hace del conocimiento de los Usuarios de la Plataforma que podrán bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador. En la mayoría de los navegadores web se ofrece la posibilidad de permitir, bloquear o eliminar las cookies instaladas en su equipo.
            </p>

            <p class="dark-grey--text">
              <span class="graphik-bold">Advertencia sobre eliminar cookies.</span> Usted puede eliminar y bloquear todas las cookies de este sitio, pero parte del sitio no funcionará o la calidad de la página web puede verse afectada. Si tiene cualquier duda acerca de nuestra política de cookies, puede contactar con esta Plataforma a través del correo electrónico proporcionado en este aviso.
            </p>

            <h3 class="text-xs-left graphik-bold mt-4 mb-1 font-18 dark-grey--text">VIII. Formas y medios para que los Usuarios manifiesten sus derechos.-</h3>

            <p class="dark-grey--text">
              De conformidad con lo dispuesto en la Ley, el Usuario podrá ejercer su derecho a que Gorila deje de usar sus datos en el momento que el Usuario lo desee, de tal modo que podrá acceder en cualquier momento a la página correspondiente, y podrá rectificar o cancelar el uso de sus datos personales así como oponerse a la transmisión de los mismos a través de los medios y procedimientos que Gorila ha implementado. 
            </p>

            <p class="dark-grey--text">
              De conformidad con lo dispuesto en la Ley, el Usuario podrá acceder, rectificar y cancelar sus datos personales así como oponerse a la divulgación y limitación del uso de los mismos a través de los procedimientos que Gorila ha implementado. El Usuario podrá hacer valer sus derechos de Acceso, Rectificación, Cancelación y Oposición (Derechos ARCO), oponerse a la divulgación y limitación uso de sus datos, así como revocar su consentimiento o manifestar negativa al tratamiento de los mismos, enviando un correo electrónico a <span class="graphik-bold">hola@gorilaseguros.com</span> dirigido a Arturo Andrés Padilla Marcos indicando lo siguiente:
            </p>

            <ol type="i" class="mb-3">
              <li>Nombre completo del titular de los datos personales.</li>
              <li>Los datos que desea dar de baja, es decir, una descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de sus derechos o la actividad sobre la cual de desea limitar el uso de los datos personales</li>
              <li>La razón por la cual el Usuario desea restringir o prohibir el uso de sus datos personales.</li>
            </ol>

            <p class="dark-grey--text">
              Asimismo cualquier otra duda y/o aclaración sobre sus derechos ARCO, puede solicitar más información al correo electrónico proporcionado anteriormente o bien al correo electrónico <span class="graphik-bold">www.gorilaseguros.com</span>.
            </p>

            <p class="dark-grey--text">
              Gorila tendrá un período de 20 (veinte) días hábiles para responder la solicitud por el medio indicado por el Usuario. No obstante, si el Usuario considera que han sido vulnerados sus derechos respecto de la protección de datos personales, tiene el derecho de acudir a la autoridad correspondiente para defender su ejercicio. La autoridad es el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI).    
            </p>

            <p class="dark-grey--text">
              De conformidad con el artículo octavo de la Ley, por el simple hecho de no manifestar oposición al leer el presente Aviso se entenderá su aceptación a los términos del mismo.
            </p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_PRIVACY_POLICY,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_PRIVACY_POLICY
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_PRIVACY_POLICY
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_PRIVACY_POLICY
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_PRIVACY_POLICY
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_PRIVACY_POLICY
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL + '/politicasdeprivacidad'
      },
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL + '/politicasdeprivacidad'
      }
    ]
  },
}
</script>

<style scoped>
</style>
